import { Col, Row } from "react-bootstrap";
import styles from "./inverter-information.module.scss";

interface IInverterInfo {
  title: string;
  value: string | React.ReactNode;
}

interface IProps {
  inverterInfo: IInverterInfo[];
}

const InverterInformation = (props: IProps) => {
  return (
    <>
      {props.inverterInfo.map((i, index) => {
        return (
          <Row key={index} className="my-3">
            <Col className={`${styles.title} col-auto pe-0`}>{i.title}:</Col>
            <Col className={styles.value}>{i.value}</Col>
          </Row>
        );
      })}
    </>
  );
};

export default InverterInformation;
