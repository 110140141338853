import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const settingsAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    requestUserDataRemoval: builder.mutation<
      void,
      {
        password: string;
      }
    >({
      transformErrorResponse,
      query({ password }) {
        return {
          url: `user/remove-user-date`,
          method: "DELETE",
          body: { password },
        };
      },
      invalidatesTags: ["Schedule"],
    }),
  }),
  overrideExisting: false,
});

export const { useRequestUserDataRemovalMutation } = settingsAPI;
