import { Col, Row } from "react-bootstrap";
import { IViewDevicesWithHighestPowerConsumptionPowerConsumerView } from "../../oversight-core/dtos/response-dtos/view-devices-with-highest-power-consumption-response-dto";
import ActiveInactiveIndicator from "../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import HighestPowerUsageDeviceCard from "./highest-power-usage-device-card";
import styles from "./highest-power-usage-devices.module.scss";

interface IProps {
  collapsed: boolean;
  onClick: () => void;
  devicesWithHighestPowerConsumption: IViewDevicesWithHighestPowerConsumptionPowerConsumerView[];
  spaceName: string;
  isFetching: boolean;
  isLive: boolean;
}

const HighestPowerUsageDevices = (props: IProps) => {
  const {
    collapsed,
    devicesWithHighestPowerConsumption,
    onClick,
    spaceName,
    isFetching,
    isLive,
  } = props;

  return (
    <div className={`${styles.highestPowerUsageDevicesContainer} p-4`}>
      <Row className="cursor-pointer" onClick={onClick}>
        <Col>
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              {isLive ? (
                <div className={`bg-red ${styles.circle}`}></div>
              ) : (
                <ActiveInactiveIndicator isActive={false} />
              )}
            </Col>
            <Col className="font-size-14 font-weight-500 text-dark">
              Devices with Highest Power Usage&nbsp;[Live] in&nbsp;
              <span className="text-light">{spaceName}</span>
            </Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <MaterialIcon
            icon="expand_more"
            className=" d-arrow"
            style={
              collapsed
                ? { transform: "rotate(0deg)" }
                : { transform: "rotate(180deg)" }
            }
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        {isFetching ? (
          <SpinnerModal show={isFetching} withOverlay={false} size="lg" />
        ) : (
          <>
            {devicesWithHighestPowerConsumption?.length === 0 && !collapsed ? (
              <Col className="text-light font-weight-400 font-size-12 text-center py-4">
                No power consumption has been recorded yet.
              </Col>
            ) : (
              <>
                {devicesWithHighestPowerConsumption
                  .slice(0, 5)
                  .map((dwhpc, index) => {
                    return (
                      <Col key={index} className="col-12 col-xxl-6 px-4 py-2">
                        <HighestPowerUsageDeviceCard
                          powerConsumerView={dwhpc}
                        />
                      </Col>
                    );
                  })}
              </>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default HighestPowerUsageDevices;
