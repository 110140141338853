import { Col, Row } from "react-bootstrap";
import { IEnergyView } from "../../../../../shared/oversight-core/interfaces/entities/energy-view";
import { IScheduledSpaceClusterAverageWeeklyEnergy } from "../../../../../shared/oversight-core/interfaces/scheduled-space-cluster-average-weekly-energy";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AverageUsageUnits from "../../../../../shared/oversight-core/ui-elements/average-usage-units/average-usage-units";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import CheckBox from "../../../../../shared/oversight-core/ui-elements/check-box/check-box";
import StatsViewTypeTwo from "../../../../../shared/oversight-core/ui-elements/stats-view/stats-view-type-two";
import Helper from "../../../../../shared/oversight-core/utils/helpers";

interface IProps {
  isFetching: boolean;
  scheduledSpaceClusterAverageWeeklyEnergy: IScheduledSpaceClusterAverageWeeklyEnergy;
  spaceClusterEstimatedConsumption: IEnergyView;
  selectedTariff: Option;
  tariffs: Option[];
  setSelectedTariff: React.Dispatch<React.SetStateAction<Option>>;
  onSaveToSchedule: () => void;
  isCheckedManual: boolean;
  setIsCheckedManual: React.Dispatch<React.SetStateAction<boolean>>;
}

const BillCalculatorTabView = (props: IProps) => {
  const {
    isFetching,
    scheduledSpaceClusterAverageWeeklyEnergy,
    spaceClusterEstimatedConsumption,
    selectedTariff,
    tariffs,
    setSelectedTariff,
    onSaveToSchedule,
    isCheckedManual,
    setIsCheckedManual,
  } = props;

  return (
    <Row
      className={`align-items-center justify-content-center justify-content-lg-between`}
    >
      <Col className="col-12 col-xxl">
        <Row className="align-items-center">
          <Col className="col-12 col-sm-6 col-xxl-auto">
            <AppButton text="Save to Schedule" onClick={onSaveToSchedule} />
          </Col>
          <Col className="col-12 col-sm-6 col-xl-6 mt-2 mt-xl-0">
            <AppSelect
              selectedValue={selectedTariff}
              options={[...tariffs]}
              onChangeOption={(selectOption) => {
                setSelectedTariff(selectOption);
              }}
              fontSize="12px"
              labelFontSize="font-size-14"
              fontWeight="500"
              fontColor="#69768b"
            />
          </Col>
        </Row>
      </Col>
      <Col className="col-auto col-lg-auto text-center mt-3">
        <Row className="justify-content-center">
          <Col
            className="col-auto text-dark font-size-12 font-weight-500 text-uppercase"
            style={{ letterSpacing: "2px" }}
          >
            Include Schedules
          </Col>
        </Row>
        <Row>
          <Col className="col-auto pe-0">
            <CheckBox
              checked={isCheckedManual}
              onChange={() => setIsCheckedManual((ps) => !ps)}
              label="Manual"
              labelFontSize="font-size-12"
            />
          </Col>
          <Col className="">
            <CheckBox
              checked={false}
              label="Semi-auto"
              labelFontSize="font-size-12"
            />
          </Col>
        </Row>
      </Col>
      <Col className="col-12 col-lg-auto col-xxl-5">
        <Row className="align-items-center justify-content-center justify-content-xl-end mt-3">
          <Col md={12} lg={"auto"} className="px-4">
            <AverageUsageUnits
              variant="bg-icon-2"
              title="Average Usage Units"
              labelValue={{
                weekDay:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weekdayDailyEnergy
                      .energyInUnits
                  ) + "",
                weekEnd:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weekendDailyEnergy
                      .energyInUnits
                  ) + "",
                weekly:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weeklyEnergy
                      .energyInUnits
                  ) + "",
              }}
              titleStyle="jakarta font-weight-500 text-dark"
              isFetching={isFetching}
            />
          </Col>
          <Col sm={6} lg="auto" className="mt-3 mt-lg-0">
            <StatsViewTypeTwo
              isFetching={isFetching}
              variant="bg-icon-2"
              labelValue={
                Helper.roundTo2(
                  spaceClusterEstimatedConsumption.energyInUnits
                ) + ""
              }
              title="Estimated Units"
            />
          </Col>
          <Col sm={6} lg="auto" className="mt-3 mt-lg-0">
            <StatsViewTypeTwo
              isFetching={isFetching}
              variant="bg-icon-2"
              labelValue={
                "Rs." +
                Helper.roundTo2(spaceClusterEstimatedConsumption.energyBill) +
                ""
              }
              title="Estimated Bill"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BillCalculatorTabView;
