import IEnergyPredictionByYearMonthRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/energy-prediction-by-year-month-request-dto";
import BillingConsumptionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/billing-consumption-response-dto";
import IEnergyPredictionByYearMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/energy-prediction-by-year-month-response-dto";
import ViewConsumedSpaceClusterByYearResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumed-space-cluster-by-year-response-dto";
import ViewConsumptionByYearOfSubSpaceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumption-by-year-of-sub-space-response-dto";
import ViewSpaceClusterEnergyByYearAndMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-space-cluster-energy-by-year-and-month-response-dto";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { EScheduleViewType } from "../../../shared/oversight-core/enums/schedule-view-type";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";

import { rootApi } from "../apiManager";

export const energyConsumptionAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addBill: builder.mutation<
      BillingConsumptionResponseDTO,
      {
        spaceClusterId: string;
        units: number;
        billCost: number;
        year: number;
        month: number;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month, billCost, units }) {
        const data = { billCost, units };
        return {
          url: `space-cluster/${spaceClusterId}/consumed-energy/bill`,
          method: "POST",
          body: data,
          params: { year, month },
        };
      },
    }),
    viewSpaceClusterEnergyByYearMonth: builder.query<
      ViewSpaceClusterEnergyByYearAndMonthResponseDTO,
      {
        spaceClusterId: string;
        year: number;
        month: number;
        viewEnergyAs: EEnergyViewType;
        viewConsumptionAs: EConsumptionViewTypes;
        viewScheduleAs: EScheduleViewType;
        zoneOffset: string;
      }
    >({
      transformErrorResponse,
      query({
        spaceClusterId,
        year,
        month,
        viewEnergyAs,
        viewConsumptionAs,
        viewScheduleAs,
        zoneOffset,
      }) {
        return {
          url: `space-cluster/${spaceClusterId}/energy/by-year-month/view`,
          method: "GET",
          params: {
            year,
            month,
            viewEnergyAs,
            viewConsumptionAs,
            viewScheduleAs,
            zoneOffset,
          },
        };
      },
    }),
    getEnergyConsumption: builder.query<
      ViewConsumedSpaceClusterByYearResponseDTO,
      {
        spaceClusterId: string;
        viewEnergyAs: EEnergyViewType;
        viewConsumptionAs: EConsumptionViewTypes;
        viewScheduleAs: EScheduleViewType;
        year: number;
        zoneOffset: string;
      }
    >({
      transformErrorResponse,
      query({
        spaceClusterId,
        year,
        viewEnergyAs,
        viewConsumptionAs,
        viewScheduleAs,
        zoneOffset,
      }) {
        return {
          url: `space-cluster/${spaceClusterId}/energy/by-year/view`,
          method: "GET",
          params: {
            year,
            viewEnergyAs,
            viewConsumptionAs,
            viewScheduleAs,
            zoneOffset,
          },
        };
      },
    }),
    getEnergyConsumptionOfSubSpace: builder.query<
      ViewConsumptionByYearOfSubSpaceResponseDTO,
      { spaceClusterId: string; spaceId: string; year: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, year }) {
        return {
          url: `space-cluster/${spaceClusterId}/space/${spaceId}/power-consumer/schedule/by-year/view`,
          method: "GET",
          params: { year },
        };
      },
    }),
    energyPredictionByYearMonth: builder.query<
      IEnergyPredictionByYearMonthResponseDTO,
      IEnergyPredictionByYearMonthRequestDTO
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month, viewEnergyAs }) {
        return {
          url: `space-cluster/${spaceClusterId}/energy-prediction/by-year-month/view`,
          method: "GET",
          params: { year, month, viewEnergyAs },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddBillMutation,
  useLazyViewSpaceClusterEnergyByYearMonthQuery,
  useLazyGetEnergyConsumptionQuery,
  useLazyGetEnergyConsumptionOfSubSpaceQuery,
  useLazyEnergyPredictionByYearMonthQuery,
} = energyConsumptionAPI;
