import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import NoChartDataView from "../no-chart-data-view/no-chart-data-view";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  data1: string[] | number[];
  data2?: string[] | number[];
  data3?: string[] | number[];
  data4?: string[] | number[];
  dataLabel1?: string;
  dataLabel2?: string;
  dataLabel3?: string;
  dataLabel4?: string;
  borderColor1: string | string[];
  borderColor2?: string;
  borderColor3?: string;
  borderColor4?: string;
  backgroundColor1: string | string[];
  backgroundColor2?: string;
  backgroundColor3?: string;
  backgroundColor4?: string;
  yAxesUnit: string;
  labels: Array<string>;
  stacked?: boolean;
  hasSecondDataSet?: boolean;
  hasThirdDataSet?: boolean;
  hasForthDataSet?: boolean;
  borderRadius?: number;
  isDisplayLegend?: boolean;
  titleCallBack?: (tooltipItems: any) => string;
  labelCallback?: (context: TooltipItem<"bar">) => string | string[];
  isChartDataAvailable?: boolean;
}

const BarChart = (props: IProps) => {
  const labels = props.labels;
  const options = {
    plugins: {
      legend: props.isDisplayLegend
        ? {
            display: true,
            position: "top" as const,
            align: "start" as const,
            labels: {
              usePointStyle: true,
              pointStyleWidth: 10,
              boxHeight: 7,
              font: {
                size: 14,
                family: "Poppins",
                weight: "normal" as const,
              },
            },
          }
        : { display: false },
      tooltip: {
        callbacks: {
          label: props.labelCallback
            ? props.labelCallback
            : (context: TooltipItem<"bar">) => {
                let label = "";
                if (context.parsed.y) {
                  if (props.yAxesUnit === "Rs.") {
                    label = props.yAxesUnit + " " + context.parsed.y.toFixed(2);
                  } else {
                    label = context.parsed.y.toFixed(2) + " " + props.yAxesUnit;
                  }
                }
                return label;
              },
          title: props.titleCallBack,
        },
      },
      datalabels: {
        display: false,
      },
      centerText: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: props.stacked,
      },
      y: {
        ticks: {
          callback: (value: any) => {
            return value + " " + props.yAxesUnit;
          },
        },

        stacked: props.stacked,
      },
    },
  };

  const legendMargin = {
    id: "legendMargin",
    beforeInit: (chart: any) => {
      const fitValue = chart.legend.fit;
      chart.legend.fit = () => {
        fitValue.bind(chart.legend)();
        return (chart.legend.height += 30);
      };
    },
  };

  const data1 = {
    labels: labels,
    datasets: [
      {
        data: props.data1,
        backgroundColor: props.backgroundColor1,
        borderWidth: 1,
        borderColor: props.borderColor1,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel1 || "",
      },
    ],
  };

  const data2 = {
    labels: labels,
    datasets: [
      {
        data: props.data1,
        backgroundColor: props.backgroundColor1,
        borderWidth: 1,
        borderColor: props.borderColor1,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel1 || "",
      },
      {
        data: props.data2,
        backgroundColor: props.backgroundColor2,
        borderWidth: 1,
        borderColor: props.borderColor2,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel2 || "",
      },
    ],
  };

  const data3 = {
    labels: labels,
    datasets: [
      {
        data: props.data1,
        backgroundColor: props.backgroundColor1,
        borderWidth: 1,
        borderColor: props.borderColor1,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel1 || "",
      },
      {
        data: props.data2,
        backgroundColor: props.backgroundColor2,
        borderWidth: 1,
        borderColor: props.borderColor2,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel2 || "",
      },
      {
        data: props.data3,
        backgroundColor: props.backgroundColor3,
        borderWidth: 1,
        borderColor: props.borderColor3,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel3 || "",
      },
    ],
  };

  const data4 = {
    labels: labels,
    datasets: [
      {
        data: props.data1,
        backgroundColor: props.backgroundColor1,
        borderWidth: 1,
        borderColor: props.borderColor1,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel1 || "",
      },
      {
        data: props.data2,
        backgroundColor: props.backgroundColor2,
        borderWidth: 1,
        borderColor: props.borderColor2,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel2 || "",
      },
      {
        data: props.data3,
        backgroundColor: props.backgroundColor3,
        borderWidth: 1,
        borderColor: props.borderColor3,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel3 || "",
      },
      {
        data: props.data4,
        backgroundColor: props.backgroundColor4,
        borderWidth: 1,
        borderColor: props.borderColor4,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel4 || "",
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        height: "100%",
      }}
    >
      <div
        style={{
          minWidth: props.isChartDataAvailable ? "calc(100vw - 350px)" : "100%",
          width: "1000px",
          minHeight: props.isChartDataAvailable
            ? "calc(100vh - 300px)"
            : "calc(100vh - 190px)",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {props.isChartDataAvailable ? (
          <Bar
            data={
              props.hasForthDataSet
                ? data4
                : props.hasThirdDataSet
                ? data3
                : props.hasSecondDataSet
                ? data2
                : data1
            }
            options={options}
            plugins={[legendMargin]}
          />
        ) : (
          <NoChartDataView />
        )}
      </div>
    </div>
  );
};

export default BarChart;
