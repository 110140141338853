import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRequestUserDataRemovalMutation } from "../../../../redux/api/settings/settingsAPI";
import {
  setPrivileges,
  setToken,
} from "../../../../redux/features/auth/auth-slice";
import { OvstErrorCode } from "../../../../shared/oversight-core/enums/ovst-error-codes";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import { IHttpError } from "../../../../shared/oversight-core/interfaces/http-errror";
import AppInput from "../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IFormInput {
  password: string;
}

const defaultFormValues: IFormInput = {
  password: "",
};

interface IProps extends ModelContainerProps {
  show: boolean;
}

const DeleteAccountModal = (props: IProps) => {
  const { show, ...rest } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);

  const [requestUserDataRemoval] = useRequestUserDataRemovalMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const password = watch("password", "");

  useEffect(() => {
    reset({ ...defaultFormValues });
    setShowError(false);
  }, [show]);

  const onSubmit = (data: IFormInput) => {
    requestUserDataRemoval({
      password: data.password,
    })
      .unwrap()
      .then(() => {
        localStorage.clear();
        dispatch(setToken(""));
        dispatch(setPrivileges([]));
        navigate(AppRoute.LOGIN);
      })
      .catch((error: IHttpError) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONS_0038
        ) {
          setShowError(true);
        }
      });
  };

  useEffect(() => {
    if (password.length === 0) {
      setShowError(false);
    }
  }, [password]);

  return (
    <ModalContainer
      {...rest}
      show={show}
      size="modal-md"
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
      title="Delete Account"
      confirmButtonVariant="red"
      onConfirm={handleSubmit(onSubmit)}
    >
      <>
        <Row>
          <Col className="font-weight-500 font-size-14 text-light">
            Are you sure you want to delete the account?
          </Col>
        </Row>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            Your data will be permanently deleted after a period of 90 days
            after account deletion. During this retention period, you can log
            back into your account to reactivate it and recover your data.
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <AppInput
              name="password"
              icon={!showPassword ? "visibility_off" : "visibility"}
              placeholder="Enter your password"
              onIconClick={() => setShowPassword(!showPassword)}
              label="Account Password"
              type={showPassword ? "text" : "password"}
              register={register("password", {
                required: "Password is required",
              })}
              errors={errors}
            />
          </Col>
        </Row>
        {showError && (
          <Row>
            <Col className="error font-size-12 mt-2">
              Please enter correct password
            </Col>
          </Row>
        )}
      </>
    </ModalContainer>
  );
};

export default DeleteAccountModal;
