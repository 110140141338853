const TRANSLATIONS: Record<string, string> = {
  "usageDataFrom.SERVICE_PROVIDER": "Service Provider",
  "usageDataFrom.MANUAL_SCHEDULE": "Manual Schedule",
  "usageDataFrom.SEMI_AUTOMATED_SCHEDULE": "Semi Automated Schedule",
  "usageDataFrom.TELEMETRY": "Telemetry",
  "usageDataFrom.PLC": "PLC",
  "scheduleViewType.ALL": "All",
  "scheduleViewType.MANUAL": "Manual",
  "scheduleViewType.SEMI_AUTOMATED": "Semi Automated",
};

export default function translate(key: string, category?: string) {
  let fullKey = `${category}.${key}`;
  if (!category) {
    fullKey = key;
  }

  return TRANSLATIONS[fullKey] || key;
}
