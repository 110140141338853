export enum AppRoute {
  // public routes
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot_password",
  RESET_PASSWORD = "/reset_password",
  CHANGE_EMAIL = "/change_email",
  MOBILE_NUMBER_VERIFICATION = "/mobile_number_verification",
  REGISTER = "/register",

  // dashboard routes
  DASHBOARD = "/dashboard",
  HOME = "/dashboard/home",
  USAGE = "/dashboard/usage",
  SCHEDULE = "/dashboard/schedule",
  LIFE_LINE_POWER = "/dashboard/life-line-power",
  LIFE_LINE_POWER_PLANNED = "/dashboard/life-line-power-planned",
  LIFE_LINE_POWER_UNPLANNED = "/dashboard/life-line-power-unplanned",
  ADD_SCHEDULE = "/dashboard/schedule/add",
  EDIT_SCHEDULE = "/dashboard/schedule/edit",
  CONTROLLERS = "/dashboard/controllers",
  ADD_CONTROLLER = "/dashboard/controllers/add-controller",
  EDIT_CONTROLLER = "/dashboard/controllers/edit-controller",
  BILL_CALCULATOR = "/dashboard/bill-calculator",
  ADD_BILL_CALCULATOR = "/dashboard/bill-calculator/add",
  EDIT_BILL_CALCULATOR = "/dashboard/bill-calculator/edit",
  SPACE_CLUSTERS = "/dashboard/space-clusters",
  SOLAR = "/dashboard/generation/solar",
  BATTERY = "/dashboard/generation/battery",
  GENERATION = "/dashboard/generation",
  LIMITATION_USAGE_GUIDE = "/dashboard/limitation-usage-guide",
  VIEW_DEMAND_RESPONSE_PROGRAM = "/dashboard/limitation-usage-guide/view-demand-response-program",
  JOIN_DEMAND_RESPONSE_PROGRAM = "/dashboard/limitation-usage-guide/join-demand-response-program",
  EDIT_DEMAND_RESPONSE_PROGRAM = "/dashboard/limitation-usage-guide/edit-demand-response-program",
  SETTINGS = "/dashboard/settings",
  METER_DASHBOARD = "/dashboard/usage/meter-dashboard",
  ENERGY_USAGE = "/dashboard/energy-usage",
  HIGHEST_POWER_CONSUMPTION = "/dashboard/usage/devices-with-highest-power-consumption",
  NOT_FOUND = "/dashboard/*",
  USER_PROFILE = "/dashboard/user-profile",
  VERIFY_MOBILE_NUMBER_OTP = "/verify-mobile-number-otp",
  CHANGE_MOBILE_NUMBER = "/change-mobile-number",
  VERIFY_MOBILE_NUMBER = "/verify-mobile-number",
  CHANGE_PASSWORD = "/change-password",
  USER_MOBILE_NUMBER_VERIFICATION = "/user_mobile_number_verification",
  MANAGE = "/dashboard/manage",
  // admin routes
  ADMIN = "/",
  ADMIN_LOGIN = "/login",
  ADMIN_DASHBOARD = "/dashboard",
  ADMIN_SITES = "/sites",
  ADMIN_SOLAR_DASHBOARD = "/solar-dashboard",
  ADMIN_BATTERY_DASHBOARD = "/battery-dashboard",
  ADMIN_USERS = "/users",
  ADMIN_SOLAR_PLANTS = "/solar_plants",
  ADMIN_SOLAR = "/solar",
  ADMIN_CONTROLLERS = "/controllers",
  ADMIN_USAGE_GUIDE = "/usage-guide",
  ADMIN_DEMAND_RESPONSE_PROGRAM = "/usage-guide/demand-response-program-view",
  AUTOMATED_DEMAND_CONTROL = "/usage-guide/automated-demand-control-request",
  ADD_MANUAL_DEMAND_CONTROL = "/usage-guide/manual-demand-control-request",
  REQUESTS = "/usage-guide/requests",
  ADD_LIMITATION = "/usage-guide/add-limitation",
  EDIT_LIMITATION = "/usage-guide/edit-limitation",
  ADMIN_SETTINGS = "/settings",
  ADMIN_PROFILE = "/profile",
  ADMIN_NOT_FOUND = "/*",

  UNAUTHORIZED = "/unauthorized",
}
