import Tooltip from "rc-tooltip";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../material-icon/material-icon";
import SpinnerModal from "../spinner/spinner";
import styles from "./stats-view.module.scss";

interface IProps {
  variant: "bg-icon-2" | "bg-icon-3" | "bg-icon-4" | "bg-icon-5" | "bg-icon-6";
  labelValue: string;
  title: string;
  fontStyle?: "normal" | "bold" | "small";
  columnClass?: string;
  fontSize?: number;
  isFetching?: boolean;
}

const StatsView = (props: IProps) => {
  const {
    variant,
    labelValue,
    title,
    columnClass,
    fontStyle = "bold",
    isFetching,
  } = props;
  return (
    <Row className="align-items-center">
      <Col xs={9}>
        <div
          className={`${styles.label} ${variant} ${styles[fontStyle]} px-3 px-md-2`}
          style={{ fontSize: props.fontSize }}
        >
          {isFetching ? (
            <SpinnerModal show={isFetching} withOverlay={false} size="sm" />
          ) : (
            <Row className="align-items-center ">
              <Col> {labelValue}</Col>
              {labelValue === "Data is not sufficient" && (
                <Col className="col-auto">
                  <Tooltip
                    placement="bottom"
                    trigger={["hover"]}
                    overlay={
                      <div className="text-light font-size-12 font-weight-500">
                        Machine Learning model requires more data to predict the
                        usage
                      </div>
                    }
                  >
                    <div className={`user-select-none`}>
                      <MaterialIcon
                        className="cursor-mouse"
                        icon="info"
                        color="#69768B"
                        size={13}
                      />
                    </div>
                  </Tooltip>
                </Col>
              )}
            </Row>
          )}
        </div>
      </Col>
      <Col className={`${styles.title} ${columnClass} col-3 ps-1 pe-0`}>
        {title}
      </Col>
    </Row>
  );
};

export default StatsView;
