export const getBatteryStatus = (value: number) => {
  switch (value) {
    case 1:
      return "Charging";
    case -1:
      return "Discharged";
    default:
      return "Standby";
  }
};
