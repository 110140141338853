import { Col, Row } from "react-bootstrap";
import ActiveInactiveIndicator from "../../ui-elements/active-inactive-indicator/active-inactive-indicator";
import Gauge from "../../ui-elements/gauge/gauge";
import Helper from "../../utils/helpers";
import styles from "./gauge-info-card.module.scss";

interface IProps {
  value: number;
  capacity: number;
  circleRation?: number;
  title?: string;
  unitType?: string;
}

const GaugeInfoCard = (props: IProps) => {
  const {
    value,
    capacity,
    circleRation,
    title = "Live Generation",
    unitType = "kWh",
  } = props;

  return (
    <div className={`${styles.infoCardContainer} p-4`}>
      <Row className="align-items-center">
        <Col className="col-auto pe-0">
          <ActiveInactiveIndicator isLive={true} />
        </Col>
        <Col className="text-dark font-size-16 font-weight-500 ps-2">
          {title}
        </Col>
      </Row>
      <Row>
        <Col className="text-light font-size-12 font-weight-400">
          Capacity: {capacity}kW
        </Col>
      </Row>
      <Row className={circleRation === 1 ? styles.gaugeHeight : `mt-2`}>
        <Col className="d-flex justify-content-center">
          {circleRation === 1 ? (
            <Gauge
              icon="solar_power"
              value={Number(Helper.roundTo2(value / 1000))}
              maxValue={capacity}
              circleRatio={1}
              unit={unitType}
              trailColor="#D08700"
              width={150}
              height={75}
              isDifferentStyles
            />
          ) : (
            <Gauge
              icon="solar_power"
              value={Number(Helper.roundTo2(value / 1000))}
              maxValue={capacity}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GaugeInfoCard;
