import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { EEnergySourceType } from "../../enums/energy-source-type";
import GenerationInfo from "../../ui-elements/generation-info/generation-info";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./generation-info-card.module.scss";

interface IProps {
  powerGeneratorType: EEnergySourceType;
  powerGenerationValue: number;
  energyGenerationValue: number;
  onClick?: () => void;
  hideExpectedValue?: boolean;
  powerGenerationCapacity?: number;
  batteryStatus?: number;
}

const GenerationInfoCard = (props: IProps) => {
  const {
    powerGeneratorType,
    powerGenerationValue,
    energyGenerationValue,
    onClick,
    hideExpectedValue = false,
    powerGenerationCapacity,
    batteryStatus,
  } = props;

  const energyName = useMemo(() => {
    switch (powerGeneratorType) {
      case EEnergySourceType.SOLAR_PV:
        return "Solar Energy";
      case EEnergySourceType.WIND_TURBINE:
        return "Wind Energy";
      case EEnergySourceType.GENERATOR:
        return "Power Energy";
      case EEnergySourceType.BATTERY:
        return "Battery Charge";
      default:
        return "";
    }
  }, [powerGeneratorType]);

  return (
    <div
      className={`${styles.infoCardContainer} cursor-pointer`}
      onClick={onClick}
    >
      <Row className="p-4 mx-0">
        <Col>
          <GenerationInfo
            powerGeneratorType={powerGeneratorType}
            powerGenerationValue={powerGenerationValue}
            powerGenerationCapacity={powerGenerationCapacity}
            batteryStatus={batteryStatus}
          />
        </Col>
      </Row>
      {!hideExpectedValue && (
        <Row className="align-items-center justify-content-between px-4 py-3">
          <Col className="col-auto pe-0">
            <Row>
              <Col className="font-size-10 text-light font-weight-400 pe-0">
                Expected {energyName} (Next Hour):
              </Col>
            </Row>
            <Row>
              <Col className="font-size-16 text-dark font-weight-500">
                {energyGenerationValue}{" "}
                {powerGeneratorType === EEnergySourceType.BATTERY ? `%` : `kWh`}
              </Col>
            </Row>
          </Col>
          <Col className="col-auto ps-0">
            <MaterialIcon icon="arrow_forward_ios" size={32} color="#69768B" />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default GenerationInfoCard;
