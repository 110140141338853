import { EUsageDataFrom } from "../enums/usage-data-from";

export const switchUsageDataFrom = (dataFrom: EUsageDataFrom) => {
  switch (dataFrom) {
    case EUsageDataFrom.SERVICE_PROVIDER:
      return "Value taken from service provider";
    case EUsageDataFrom.TELEMETRY:
      return "Value taken from smart controller(s)";
    case EUsageDataFrom.PLC:
      return "Value taken from PLC data";
    default:
      return "";
  }
};
