export enum EBatteryStatusType {
  NO_BATTERY = "NO_BATTERY",
  STANDBY = "STANDBY",
  DISCHARGING = "DISCHARGING",
  CHARGING = "CHARGING",
  WAITING_FOR_CHARGE = "WAITING_FOR_CHARGE",
  WAITING_FOR_DISCHARGE = "WAITING_FOR_DISCHARGE",
}

export const batteryStatusTypeText = {
  [EBatteryStatusType.NO_BATTERY]: "No battery",
  [EBatteryStatusType.STANDBY]: "Standby",
  [EBatteryStatusType.DISCHARGING]: "Discharging",
  [EBatteryStatusType.CHARGING]: "Charging",
  [EBatteryStatusType.WAITING_FOR_CHARGE]: "Waiting for charge",
  [EBatteryStatusType.WAITING_FOR_DISCHARGE]: "Waiting for discharging",
};
