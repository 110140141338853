import moment from "moment";
import { EDateTypes } from "../enums/date-types";

export const formatDate = (selectDate: Date) => {
  return moment(selectDate).toISOString(true);
};

export const getMonthRangeToDisplay = (weekRange: Date[]) => {
  const startingMonthAndYear = {
    month: weekRange[0].getMonth(),
    year: weekRange[0].getFullYear(),
  };
  const endingMonthAndYear = {
    month: weekRange[6].getMonth(),
    year: weekRange[6].getFullYear(),
  };

  const momentStartOfTheWeek = moment(weekRange[0]);
  const momentEndOfTheWeek = moment(weekRange[6]);

  if (startingMonthAndYear.month === endingMonthAndYear.month) {
    return `${momentStartOfTheWeek.format("MMM - YYYY")}`;
  } else if (startingMonthAndYear.year !== endingMonthAndYear.year) {
    return `${momentStartOfTheWeek.format(
      "MMM - YYYY"
    )} - ${momentEndOfTheWeek.format("MMM - YYYY")}`;
  } else {
    return `${momentStartOfTheWeek.format("MMM")} - ${momentEndOfTheWeek.format(
      "MMM"
    )} ${momentStartOfTheWeek.format("YYYY")}`;
  }
};

export const getWeekRange = (date: Date, selectedDate: number): Date[] => {
  const dayOfWeek = date.getDay();

  let difference = dayOfWeek - 1;

  if (difference < 0) {
    difference = 6;
  }

  const dates: Date[] = [];
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(date.getDate() - difference);

  for (let i = 0; i < 7; i++) {
    const newDate = new Date(startOfWeek);
    dates.push(new Date(newDate.setDate(newDate.getDate() + i)));
  }

  return dates;
};

export const getFromDate = (selectedDate: Date, selectedDateType: string) => {
  switch (selectedDateType) {
    case EDateTypes.HOURLY:
      return moment(selectedDate).startOf("day").toISOString();
    case EDateTypes.DAILY:
      return moment(selectedDate).startOf("month").toISOString();
    case EDateTypes.MONTHLY:
      return moment(selectedDate).startOf("year").toISOString();
    default:
      return moment().subtract(10, "years").startOf("year").toISOString();
  }
};

export const getToDate = (selectedDate: Date, selectedDateType: string) => {
  const isCurrentDay = moment(selectedDate).isSame(moment(), "day");
  const isCurrentMonth = moment(selectedDate).isSame(moment(), "month");
  const isCurrentYear = moment(selectedDate).isSame(moment(), "year");

  switch (selectedDateType) {
    case EDateTypes.HOURLY:
      return isCurrentDay
        ? moment().toISOString()
        : moment(selectedDate).endOf("day").toISOString();
    case EDateTypes.DAILY:
      return isCurrentMonth
        ? moment().toISOString()
        : moment(selectedDate).endOf("month").toISOString();
    case EDateTypes.MONTHLY:
      return isCurrentYear
        ? moment().toISOString()
        : moment(selectedDate).endOf("year").toISOString();
    default:
      return moment().toISOString();
  }
};
