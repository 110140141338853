import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyGetSpaceClustersXYQuery } from "../../../../../redux/api/dashboard/dashboardAPI";
import {
  useLazyViewUserPlantDailyPowerQuery,
  useLazyViewUserPlantDetailsQuery,
  useLazyViewUserPlantGenerationQuery,
  useLazyViewUserPlantInvertersQuery,
} from "../../../../../redux/api/generation/generationAPI";
import { useLazyGetSpacesQuery } from "../../../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  selectSolarFilter,
  setSolarFilter,
} from "../../../../../redux/features/solar-filter/solar-filter-slice";
import { selectProfile } from "../../../../../redux/features/user/user-slice";
import {
  ISpaceClusterWithPowerGeneratorIds,
  SpaceClustersResponseDTO,
} from "../../../../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import IViewUserSolarPlantDetailsResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-details-response-dto";
import IViewUserSolarPlantEnergyResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-energy-response-dto";
import IViewUserSolarPlantPowerResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-power-response-dto";
import IViewUserSolarPlantInvertersResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plantInverters-response-dto";
import { EDateTypes } from "../../../../../shared/oversight-core/enums/date-types";
import { EPrivileges } from "../../../../../shared/oversight-core/enums/privileges";
import { EUnitType } from "../../../../../shared/oversight-core/enums/unit-type";
import { IGridInverterView } from "../../../../../shared/oversight-core/interfaces/grid-inverter-view";
import { ISolarPlantView } from "../../../../../shared/oversight-core/interfaces/solar-plant-view";
import { ISpaceCluster } from "../../../../../shared/oversight-core/interfaces/space-cluster";
import GaugeInfoCard from "../../../../../shared/oversight-core/shared-components/gauge-info-card/gauge-info-card";
import InverterInfoCard from "../../../../../shared/oversight-core/shared-components/inverter-info-card/inverter-info-card";
import SolarInfoCard from "../../../../../shared/oversight-core/shared-components/solar-info-card/solar-info-card";
import WetherInfo from "../../../../../shared/oversight-core/shared-components/wether-info/wether-info";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AreaChart from "../../../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ArrowButton from "../../../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import ButtonWithBadge from "../../../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import Pagination from "../../../../../shared/oversight-core/ui-elements/pagination/pagination";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import SolarFilterSidePanel from "../solar-filter-side-panel/solar-filter-side-panel";

interface ChartData {
  values: number[];
  labels: string[];
}

const defaultChartDataValues = {
  values: [],
  labels: [],
};

const unitTypes: Option[] = [
  {
    value: EUnitType.POWER,
    label: "Power(kW)",
  },
  {
    value: EUnitType.ENERGY,
    label: "Energy(kWh)",
  },
];

const Solar = () => {
  const solarFilterStore = useSelector(selectSolarFilter);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  const [selectedUnitType, setSelectedUnitType] = useState<Option>({
    ...unitTypes[0],
  });
  const [powerChartData, setPowerChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [energyChartData, setEnergyChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [solarPlant, setSolarPlant] = useState<ISolarPlantView>();
  const [solarPlantInverters, setSolarPlantInverters] = useState<
    IGridInverterView[]
  >([]);
  const [showSolarFilterSidePanel, setShowSolarFilterSidePanel] =
    useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState({ pageNumber: 0 });
  const [
    spaceClustersWithPowerGeneratorIds,
    setSpaceClustersWithPowerGeneratorIds,
  ] = useState<ISpaceClusterWithPowerGeneratorIds[]>([]);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [selectedSpace, setSelectedSpace] =
    useState<ISpaceClusterWithPowerGeneratorIds>();
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);

  const [triggerGetSpaceClusters] = useLazyGetSpacesQuery();
  const [
    triggerGetSpaceClustersWithPowerGeneratorIds,
    { isFetching: isFetchingSpaceClustersWithPowerGeneratorIds },
  ] = useLazyGetSpaceClustersXYQuery();
  const [triggerUserPlantDetails, { isFetching: isFetchingPlantDetails }] =
    useLazyViewUserPlantDetailsQuery();
  const [triggerUSerPlantInverters, { isFetching: isFetchingPlantInverters }] =
    useLazyViewUserPlantInvertersQuery();
  const [triggerDailyPlantPower, { isFetching: isFetchingDailyPlantPower }] =
    useLazyViewUserPlantDailyPowerQuery();
  const [triggerDailyPlantEnergy, { isFetching: isFetchingDailyPlantEnergy }] =
    useLazyViewUserPlantGenerationQuery();

  useEffect(() => {
    triggerGetSpaceClustersWithPowerGeneratorIds()
      .unwrap()
      .then((res: SpaceClustersResponseDTO) => {
        setSpaceClustersWithPowerGeneratorIds(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClustersWithPowerGeneratorIds([]);
      });
  }, [triggerGetSpaceClustersWithPowerGeneratorIds]);

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    if (spaceClusters.length > 0) {
      const selectedSpaceCluster = spaceClustersWithPowerGeneratorIds.find(
        (space) => space.id === billingSpaceFilter.spaceCluster.id
      );

      setSelectedSpace(
        selectedSpaceCluster ? { ...selectedSpaceCluster } : undefined
      );
    }
  }, [
    spaceClusters,
    billingSpaceFilter.spaceCluster.id,
    spaceClustersWithPowerGeneratorIds,
  ]);

  useEffect(() => {
    if (selectedSpace?.plantId) {
      triggerUserPlantDetails({ plantId: selectedSpace?.plantId })
        .unwrap()
        .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
          setSolarPlant(res.solarPlantView);
          setIsFirstTimeLoading(false);
        })
        .catch(() => {
          console.log("Failed to get plant details");
          setIsFirstTimeLoading(false);
        });
    }
  }, [triggerUserPlantDetails, selectedSpace]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedSpace?.plantId) {
        triggerUserPlantDetails({ plantId: selectedSpace?.plantId })
          .unwrap()
          .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
            setSolarPlant(res.solarPlantView);
          })
          .catch(() => {
            console.log("Failed to get plant details");
          });
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [triggerUserPlantDetails, selectedSpace]);

  useEffect(() => {
    if (billingSpaceFilter) {
      setIsFirstTimeLoading(true);
    }
  }, [billingSpaceFilter]);

  useEffect(() => {
    if (
      selectedUnitType.value === EUnitType.POWER &&
      solarFilterStore &&
      selectedSpace?.plantId
    ) {
      triggerDailyPlantPower({
        plantId: selectedSpace.plantId,
        year: new Date(solarFilterStore.selectedDate).getFullYear(),
        month: new Date(solarFilterStore.selectedDate).getMonth() + 1,
        day: new Date(solarFilterStore.selectedDate).getDate(),
      })
        .unwrap()
        .then((res: IViewUserSolarPlantPowerResponseDTO) => {
          const power = res.solarPlantPowerView.solarPower;
          setPowerChartData({
            values: power.map((p) => p.power / 1000),
            labels: power.map((p) => moment(p.date).utc().format("HH:mm")),
          });
        })
        .catch(() => {
          setPowerChartData({ ...defaultChartDataValues });
        });
    }
  }, [
    triggerDailyPlantPower,
    solarFilterStore,
    selectedUnitType.value,
    selectedSpace,
  ]);

  useEffect(() => {
    if (
      selectedUnitType.value === EUnitType.ENERGY &&
      solarFilterStore &&
      selectedSpace?.plantId
    ) {
      triggerDailyPlantEnergy({
        plantId: selectedSpace.plantId,
        year: new Date(solarFilterStore.selectedDate).getFullYear(),
        month: new Date(solarFilterStore.selectedDate).getMonth() + 1,
        day: new Date(solarFilterStore.selectedDate).getDate(),
        viewType: solarFilterStore.selectedDateType,
      })
        .unwrap()
        .then((res: IViewUserSolarPlantEnergyResponseDTO) => {
          const energy = res.solarPlantEnergyView.solarEnergy;
          setEnergyChartData({
            values: energy.map((e) => e.power),
            labels: energy.map((e) =>
              moment(e.date)
                .utcOffset("+05:30")
                .format(
                  solarFilterStore.selectedDateType === EDateTypes.DAILY
                    ? "MM-DD"
                    : solarFilterStore.selectedDateType === EDateTypes.MONTHLY
                    ? "MM-YYYY"
                    : "YYYY"
                )
            ),
          });
        })
        .catch(() => {
          setEnergyChartData({ ...defaultChartDataValues });
        });
    }
  }, [
    triggerDailyPlantEnergy,
    solarFilterStore,
    selectedUnitType.value,
    selectedSpace,
  ]);

  useEffect(() => {
    if (selectedSpace?.plantId) {
      triggerUSerPlantInverters({
        plantId: selectedSpace.plantId,
        pageNumber: filter.pageNumber,
        pageSize: 2,
      })
        .unwrap()
        .then((res: IViewUserSolarPlantInvertersResponseDTO) => {
          setSolarPlantInverters(res.genericPage.elements[0].gridInverterViews);
          setTotalElements(res.genericPage.totalElements);
        })
        .catch(() => {
          setTotalElements(0);
        });
    }
  }, [triggerUSerPlantInverters, filter.pageNumber, selectedSpace]);

  const onFilter = (selectedDate: Date, selectedDateType: EDateTypes) => {
    dispatch(
      setSolarFilter({
        selectedDate: moment(selectedDate).valueOf(),
        selectedDateType,
      })
    );
    setShowSolarFilterSidePanel(false);
  };

  const onClearAll = () => {
    dispatch(
      setSolarFilter({
        selectedDate: moment().valueOf(),
        selectedDateType: EDateTypes.DAILY,
      })
    );
    setShowSolarFilterSidePanel(false);
  };

  return (
    <div className="position-relative">
      {profile?.privileges.includes(EPrivileges.GENERATION_READ_PRIVILEGE) &&
        !profile?.privileges.includes(
          EPrivileges.EXTERNAL_GENERATION_READ_PRIVILEGE
        ) && (
          <Row className="mx-0">
            <Col>
              <ArrowButton onBack={() => navigate(-1)} />
            </Col>
          </Row>
        )}
      {selectedSpace?.plantId ? (
        <>
          <Row className="mt-1 g-4">
            <Col className="col-12 col-lg-6 col-xl-4">
              <GaugeInfoCard
                value={solarPlant?.pvPower ?? 0}
                capacity={solarPlant?.pvCapacity ?? 0}
              />
            </Col>
            <Col className="col-12 col-sm-6 col-lg-3 col-xl-4 col-xxl">
              <SolarInfoCard
                title="Today Generation"
                value={`${solarPlant?.dailyGeneration ?? 0} kWh`}
                icon="solar_power"
                iconColor="#D08700"
                backgroundColor="#F7F8EE"
                isFetching={isFetchingPlantDetails && isFirstTimeLoading}
              />
            </Col>
            <Col className="col-12 col-sm-6 col-lg-3 col-xl-4 col-xxl">
              <SolarInfoCard
                title="Today Income"
                value={`${solarPlant?.dailyIncome ?? 0} ${
                  solarPlant?.currency ?? ""
                }`}
                icon="payments"
                iconColor="#8EBB13"
                backgroundColor="#F5F8EE"
                isFetching={isFetchingPlantDetails && isFirstTimeLoading}
              />
            </Col>
            <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
              <SolarInfoCard
                title="Total Generation"
                value={`${solarPlant?.totalGeneration ?? 0} kWh`}
                icon="solar_power"
                iconColor="#B867B6"
                backgroundColor="#F8F6F8"
                isFetching={isFetchingPlantDetails && isFirstTimeLoading}
              />
            </Col>
            <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
              <SolarInfoCard
                title="Total Income"
                value={`${solarPlant?.totalIncome ?? 0} ${
                  solarPlant?.currency ?? ""
                }`}
                icon="payments"
                iconColor="#26A767"
                backgroundColor="#F0FAF6"
                isFetching={isFetchingPlantDetails && isFirstTimeLoading}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-12 col-xl-9">
              <div className="container-white position-relative">
                <Row className="align-items-center justify-content-end">
                  <Col className="col-auto">
                    <AppSelect
                      className="mt-1"
                      selectedValue={{ ...selectedUnitType }}
                      options={[...unitTypes]}
                      onChangeOption={(selectedOption) => {
                        setSelectedUnitType(selectedOption);
                      }}
                      id="unit-type"
                    />
                  </Col>
                  <Col className="col-auto ps-1">
                    <ButtonWithBadge
                      text="Filter"
                      icon="filter_alt"
                      onClick={() => {
                        setShowSolarFilterSidePanel(true);
                      }}
                      badgeValue={0}
                    />
                  </Col>
                </Row>
                <AreaChart
                  borderColor1="#2DEC9F"
                  backgroundColor1="#29CC3917"
                  label1="Solar Power"
                  yAxesUnit={
                    selectedUnitType.value === EUnitType.POWER ? `kW` : `kWh`
                  }
                  labels={
                    selectedUnitType.value === EUnitType.POWER
                      ? powerChartData.labels
                      : energyChartData.labels
                  }
                  data1={
                    selectedUnitType.value === EUnitType.POWER
                      ? powerChartData.values
                      : energyChartData.values
                  }
                  isChartDataAvailable={
                    selectedUnitType.value === EUnitType.POWER
                      ? powerChartData.values.length > 0
                      : energyChartData.values.length > 0
                  }
                />
                <SpinnerModal
                  show={isFetchingDailyPlantPower || isFetchingDailyPlantEnergy}
                />
              </div>
            </Col>
            <Col className="col-12 col-xl-3 mt-4 mt-xl-0">
              <WetherInfo
                longitude={solarPlant?.longitude ?? 0}
                latitude={solarPlant?.latitude ?? 0}
                address={solarPlant?.address ?? ""}
                isFetching={isFetchingPlantDetails && isFirstTimeLoading}
              />
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col className="text-dark font-weight-600 font-size-24">
              Inverters
            </Col>
          </Row>
          {isFetchingPlantInverters ? (
            <Row>
              <Col>
                <SpinnerModal
                  show={isFetchingPlantInverters}
                  withOverlay={false}
                />
              </Col>
            </Row>
          ) : (
            <>
              {solarPlantInverters.length > 0 ? (
                <div className="mt-4 position-relative">
                  {solarPlantInverters.map((s) => {
                    return (
                      <Row key={s.plantId} className="mt-2">
                        <Col>
                          <InverterInfoCard gridInverter={s} />
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              ) : (
                <>
                  {!isFetchingPlantInverters && (
                    <div className="mt-4 container-dash text-center text-light font-weight-400 font-size-14">
                      There are no inventers
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {solarPlantInverters.length > 0 && (
            <Pagination
              itemsPerPage={2}
              length={totalElements}
              currentPage={filter.pageNumber + 1}
              updateCurrentPage={(pn) => {
                setFilter((ps) => ({ ...ps, pageNumber: pn - 1 }));
              }}
            />
          )}
        </>
      ) : (
        <>
          {!isFetchingSpaceClustersWithPowerGeneratorIds && (
            <div className="container-dash text-center text-light font-weight-400 font-size-12 mt-4">
              No assign solar plant in this space cluster.
            </div>
          )}
        </>
      )}
      <SolarFilterSidePanel
        showFilter={showSolarFilterSidePanel}
        setShowFilter={(value: boolean) => {
          setShowSolarFilterSidePanel(value);
        }}
        onFilter={onFilter}
        onClearAll={onClearAll}
        selectedUnitType={selectedUnitType.value as EUnitType}
      />
      <SpinnerModal show={isFetchingSpaceClustersWithPowerGeneratorIds} />
    </div>
  );
};

export default Solar;
