import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import Tank from "../../ui-elements/tank/tank";
import styles from "./battery-info-card.module.scss";

interface IProps {
  value: number;
  batteryCapacity: number;
}

const BatteryInfoCard = (props: IProps) => {
  const { value, batteryCapacity } = props;
  return (
    <div className={`${styles.infoCardContainer} p-4`}>
      <Row>
        <Col className="text-dark font-size-16 font-weight-500">
          Battery Capacity
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center justify-content-lg-start mt-1 gy-3">
        <Col className="col-auto">
          <Tank value={value} />
        </Col>
        <Col className="col-auto">
          <Row>
            <Col>
              <MaterialIcon icon="battery_charging_80" color="#5E45B8" />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="text-dark font-weight-500 font-size-36">
                {value}%
              </label>
              {batteryCapacity && (
                <label className="text-light font-weight-400 font-size-12 ps-1">
                  ({batteryCapacity}kW)
                </label>
              )}
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              <MaterialIcon icon="flash_on" color="#69768B" fill size={16} />
            </Col>
            <Col className="text-light font-weight-400 font-size-14 ps-1">
              5% Charge in 1h
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BatteryInfoCard;
