import { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SettingItem from "../../../shared/oversight-core/shared-components/setting-item/setting-item";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import Search from "../../../shared/oversight-core/ui-elements/search/search";
import DeleteAccountModal from "./delete-accont-modal/delete-accont-modal";
import styles from "./settings.module.scss";

const Settings = () => {
  const [selectedItemId, setSelectedItemId] = useState(4);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const getSettingTitle = useMemo(() => {
    switch (selectedItemId) {
      case 1:
        return "Privacy";
      case 2:
        return "Features";
      case 3:
        return "General";
      default:
        return "Account";
    }
  }, [selectedItemId]);

  return (
    <div className={`${styles.container} px-2 px-lg-4`}>
      <Row>
        <Col
          className={`col-3 col-xl-2 ${styles.settingItemContainer} d-none d-lg-block`}
        >
          <div className={styles.settingItems}>
            <SettingItem
              items={[{ itemName: "Account", id: 4 }]}
              onChange={(id) => setSelectedItemId(id)}
              selectedItemId={selectedItemId}
            />
          </div>
        </Col>
        <Col>
          <div className={styles.settings}>
            <Row>
              <Col className="col-12 col-sm-7 col-xl-4">
                <Search
                  onSearch={() => {}}
                  handleSearchChange={(searchText) => {}}
                  value={""}
                  placeholder="Search Settings"
                />
              </Col>
            </Row>
            <Row>
              <Col className="font-weight-500 font-size-24 text-dark mt-5">
                {getSettingTitle}
              </Col>
            </Row>
            <div className={styles.hr}></div>
            {selectedItemId === 4 && (
              <>
                <Row className="mt-4">
                  <Col className="text-dark font-weight-500 font-size-16">
                    Delete Account
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-12">
                    Your data will be retained for a period of 90 days after
                    account deletion. During this retention period, you can log
                    back into your account to reactivate it and recover your
                    data.
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="col-auto">
                    <AppButton
                      text="Delete Account"
                      variant="red"
                      className="px-4"
                      onClick={() => setShowDeleteAccountModal(true)}
                    />
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
      </Row>
      <DeleteAccountModal
        show={showDeleteAccountModal}
        onCancel={() => setShowDeleteAccountModal(false)}
        onClose={() => setShowDeleteAccountModal(false)}
      />
    </div>
  );
};

export default Settings;
