import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useSaveToScheduleMutation,
  useViewWeeklyManualSchedulesQuery,
} from "../../../redux/api/schedule/scheduleAPI";
import {
  useLazyGetSpaceQuery,
  useLazyGetSpacesQuery,
  useLazyListTariffsQuery,
} from "../../../redux/api/space/spaceAPI";
import {
  selectBillCalculatorFilter,
  setBillCalculatorFilter,
  setBillCalculatorFiltersSpaceAndSpaceDetails,
} from "../../../redux/features/bill-calculator-filter/bill-calculator-filter-slice";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import SpacePathViewBreadcrumb from "../../../shared/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import SubSpaceClusterViewResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/sub-space-cluster-view-response-dto";
import {
  default as IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
  default as ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-monthly-energy-for-sub-space-cluster-response-dto";
import ViewWeeklyManualSchedulesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-manual-schedules-response-dto";
import { EScheduleTagType } from "../../../shared/oversight-core/enums/schedule-tag-type";
import { ETimeGridYAxisType } from "../../../shared/oversight-core/enums/time-grid-y-axis-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import { IScheduledShallowView } from "../../../shared/oversight-core/interfaces/scheduled-shallow-view";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import {
  DataColumn,
  Header,
  TimeGridEvent,
} from "../../../shared/oversight-core/shared-components/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../../shared/oversight-core/shared-components/calendar/useTimeGrid";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import { Option } from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import NavigateWeeks from "../../../shared/oversight-core/ui-elements/navigate-weeks/navigate-weeks";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsViewTypeTwo from "../../../shared/oversight-core/ui-elements/stats-view/stats-view-type-two";
import {
  getMonthRangeToDisplay,
  getWeekRange,
} from "../../../shared/oversight-core/utils/date-utils";
import Helper from "../../../shared/oversight-core/utils/helpers";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import { scrollToView } from "../../../shared/oversight-core/utils/scroll-to-view";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";
import styles from "./bill-calculator.module.scss";
import { IAddBillCalculatorProps } from "./components/add-update-bill-calculator/add-update-bill-calculator";
import BillCalculatorTabView from "./components/bill-calculator-tab-view/bill-calculator-tab-view";
import MiniCalendar from "./components/mini-calendar/mini-calendar";
import BillCalculatorDeleteModal from "./components/modals/bill-calculator-delete-modal/bill-calculator-delete-modal";
import CreateModal from "./components/modals/create-modal/create-modal";

type ColumnDataType = Date | string; // x-axis can be Date or Device ID

const navigateToBillCalculatorAddEditPage = (
  selectedDate: Date | undefined,
  event: TimeGridEvent<ColumnDataType, IScheduledShallowView>,
  yAxis: ETimeGridYAxisType,
  spaceClusterId: string,
  subRootSpaceId: string
) => {
  const dateToUse =
    yAxis === ETimeGridYAxisType.DATE ? event.columnData : selectedDate;
  const initialIsoTimeList =
    !event.data?.id && dateToUse
      ? [
          {
            fromDate: moment(dateToUse)
              .startOf("day")
              .add(event.startTime, "minute")
              .toISOString(),
            toDate: moment(dateToUse)
              .startOf("day")
              .add(event.endTime, "minute")
              .toISOString(),
          },
        ]
      : undefined;

  const initialDeviceIds =
    !event.data?.id && event.columnData && yAxis === ETimeGridYAxisType.DEVICE
      ? [event.columnData.toString()]
      : event.data
      ? event.data.powerConsumers.map((pc) => pc.id)
      : undefined;

  const addScheduleState: IAddBillCalculatorProps = {
    scheduleId: event.data?.id,
    spaceClusterId,
    subRootSpaceId,
    navigateLocation: [-1],
    initialIsoTimeList,
    initialDeviceIds,
  };
  const routePath = event.data?.id
    ? AppRoute.EDIT_BILL_CALCULATOR
    : AppRoute.ADD_BILL_CALCULATOR;
  return {
    routePath,
    addScheduleState,
  };
};

const defaultScheduleMonthlyEnergyForSubSpaceCluster: IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO =
  {
    spaceClusterEnergy: {
      energyBill: 0,
      energyInUnits: 0,
    },
    spaceClusterAverageWeeklyEnergy: {
      weekdayDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weekendDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weeklyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
    },
  };

const BillCalculator = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const billCalculatorFilterStore = useSelector(selectBillCalculatorFilter);
  const billingSpaceFilterStore = useSelector(selectBillingSpaceFilter);
  const [noDevices, setNoDevices] = useState(false);
  const [noSmartDevices, setNoSmartDevices] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteScheduleData, setDeleteScheduleData] = useState({
    scheduleId: "",
    scheduleTitle: "",
  });
  const [isScheduleDeleted, setIsScheduleDeleted] = useState(false);
  const [deviceHeaders, setDeviceHeaders] = useState<
    { deviceName: string; deviceId: string }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date(billCalculatorFilterStore.selectedDate)
  );
  const [events, setEvents] = useState<
    DataColumn<ColumnDataType, IScheduledShallowView>[]
  >([]);
  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), billCalculatorFilterStore.selectedDate)
  );
  const [
    scheduleMonthlyEnergyForSubSpaceCluster,
    setScheduleMonthlyEnergyForSubSpaceCluster,
  ] =
    useState<ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO>(
      { ...defaultScheduleMonthlyEnergyForSubSpaceCluster }
    );
  const [loading, setIsLoading] = useState(true);
  const [weeklyManualScheduleEstimation, setWeeklyManualScheduleEstimation] =
    useState<ViewWeeklyManualSchedulesResponseDTO>();
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [isGoToCurrentTime, setIsGoToCurrentTime] = useState(false);
  const [tariffs, setTariffs] = useState<Option[]>([]);
  const [selectedTariff, setSelectedTariff] = useState<Option>({
    label: "",
    value: "",
  });
  const [isCheckedManual, setIsCheckedManual] = useState(false);

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceCluster }] =
    useLazyGetSpacesQuery();
  const [triggerGetSpace, { isFetching: isFetchingSpace }] =
    useLazyGetSpaceQuery();
  const [
    triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster,
    { isFetching: isFetchingViewManualScheduleMonthlyEnergy },
  ] = useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery();
  const [triggerListTariffs, { isFetching: isTariffFetching }] =
    useLazyListTariffsQuery();
  const [triggerSaveToSchedule, { isLoading: isLoadingSaveToSchedule }] =
    useSaveToScheduleMutation();

  // Build grid headers. This can be Dates of week or Devices
  const headers: Header<ColumnDataType>[] = [];

  switch (billCalculatorFilterStore.timeGridYAxis) {
    case ETimeGridYAxisType.DATE:
      for (let cIndex = 0; cIndex < 7; cIndex++) {
        headers.push({
          columnId: moment(weekRange[cIndex]).toDate(),
          title: moment(weekRange[cIndex]).format("DD"),
          value: (
            <div className="d-flex flex-column">
              <div>{moment(weekRange[cIndex]).format("ddd")}</div>
              <div
                className={`${
                  moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                    ? `today-grid-style`
                    : ``
                } px-1`}
              >
                {moment(weekRange[cIndex]).format("DD")}
              </div>
            </div>
          ),
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
    case ETimeGridYAxisType.DEVICE:
      for (let cIndex = 0; cIndex < deviceHeaders.length; cIndex++) {
        headers.push({
          columnId: deviceHeaders[cIndex].deviceId,
          title: deviceHeaders[cIndex].deviceName,
          value: <div>{deviceHeaders[cIndex].deviceName}</div>,
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
  }

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    triggerListTariffs().then((response) => {
      if (!response.data) {
        return;
      }

      const tempTariffs: Option[] = response.data.tariffs.map((tariff) => {
        return {
          label: `${tariff.code} - ${tariff.description}`,
          value: tariff.id,
          data: { ...tariff },
        };
      });
      setTariffs(tempTariffs);
    });
  }, [triggerListTariffs]);

  useEffect(() => {
    if (
      billCalculatorFilterStore.selectedSpaceDetails.spaceClusterId &&
      billCalculatorFilterStore.selectedSpaceDetails.spaceId
    ) {
      triggerGetSpace({
        clusterId:
          billCalculatorFilterStore.selectedSpaceDetails.spaceClusterId,
        spaceId: billCalculatorFilterStore.selectedSpaceDetails.spaceId,
      })
        .unwrap()
        .then((response: SubSpaceClusterViewResponseDTO) => {
          setNoDevices(
            response.subSpaceCluster.rootSpace.powerConsumers.length < 1
          );
          setNoSmartDevices(
            response.subSpaceCluster.rootSpace.smartDevices.filter(
              (smartDevice) => smartDevice.linkedPowerConsumer
            ).length === 0
          );

          const devices = response.subSpaceCluster.rootSpace.powerConsumers;

          const deviceHeaders: { deviceName: string; deviceId: string }[] = [];

          devices.forEach((device) => {
            deviceHeaders.push({
              deviceName: device.name,
              deviceId: device.id,
            });
          });

          setDeviceHeaders(deviceHeaders);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    billCalculatorFilterStore.timeGridYAxis,
    billCalculatorFilterStore.selectedSpaceDetails,
  ]);

  let isCurrentWeek = false;
  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const onCurrentTimeIndicatorRender = (elemId: string) => {
    if (isGoToCurrentTime) {
      scrollToView(elemId);
      setIsGoToCurrentTime(false);
    }
  };

  const isShowTimeBar = isCurrentWeek;

  const { calendarContent } = useTimeGrid<
    ColumnDataType,
    IScheduledShallowView
  >(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        onCurrentTimeIndicatorRender,
        titleToShow: "New Bill Estimation",
        isDeviceView:
          billCalculatorFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE,
        viewEventModalTemplate: (events, onClose) => {
          return (
            <CreateModal<ColumnDataType>
              id=""
              events={events}
              onEditClick={(event) => {
                const { routePath, addScheduleState } =
                  navigateToBillCalculatorAddEditPage(
                    new Date(billCalculatorFilterStore.selectedDate),
                    event,
                    billCalculatorFilterStore.timeGridYAxis,
                    billCalculatorFilterStore.selectedSpaceDetails
                      .spaceClusterId,
                    event.data?.rootSpaceId || ""
                  );
                navigate(routePath, {
                  state: addScheduleState,
                });
              }}
              onDelete={(event) => {
                onClose();
                setDeleteScheduleData({
                  scheduleId: event.data?.id || "",
                  scheduleTitle: event.data?.title || "",
                });
                setShowDeleteModal(true);
              }}
              onClose={onClose}
            />
          );
        },
        onEventChanged: (event) => {
          const { routePath, addScheduleState } =
            navigateToBillCalculatorAddEditPage(
              new Date(billCalculatorFilterStore.selectedDate),
              event,
              billCalculatorFilterStore.timeGridYAxis,
              billCalculatorFilterStore.selectedSpaceDetails.spaceClusterId,
              billCalculatorFilterStore.selectedSpaceDetails.spaceId
            );
          navigate(routePath, {
            state: addScheduleState,
          });
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: TimeGridEvent<ColumnDataType, IScheduledShallowView>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                <Col
                  className={`${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>
              {!isGrouped && (
                <Row>
                  <Col className="font-size-9 font-weight-500">
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Events
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [
      headers,
      billCalculatorFilterStore.selectedSpaceDetails,
      events,
      billCalculatorFilterStore.timeGridYAxis,
      isShowTimeBar,
    ])
  );

  const startDate = weekRange[0];
  const validForRequest =
    billCalculatorFilterStore.selectedSpaceDetails.spaceClusterId &&
    billCalculatorFilterStore.selectedSpaceDetails.spaceId &&
    ((deviceHeaders.length > 0 &&
      billCalculatorFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE) ||
      billCalculatorFilterStore.timeGridYAxis === ETimeGridYAxisType.DATE);
  const {
    data: manualScheduleResponse,
    isFetching: isFetchingViewWeeklyManualSchedules,
  } = useViewWeeklyManualSchedulesQuery(
    validForRequest
      ? {
          spaceClusterId:
            billCalculatorFilterStore.selectedSpaceDetails.spaceClusterId,
          subRootSpaceId:
            billCalculatorFilterStore.selectedSpaceDetails.spaceId,
          startDate: moment(startDate).startOf("day").toISOString(true),
          scheduleUsageType: isCheckedManual
            ? EScheduleTagType.ALL
            : EScheduleTagType.ESTIMATOR,
        }
      : skipToken
  );

  useEffect(() => {
    if (manualScheduleResponse) {
      setWeeklyManualScheduleEstimation(manualScheduleResponse);
    }
  }, [manualScheduleResponse]);

  useEffect(() => {
    if (validForRequest && manualScheduleResponse) {
      setEvents([]);
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<ColumnDataType, IScheduledShallowView>
      > = {};
      manualScheduleResponse?.shallowScheduleViews?.forEach(
        (sp: IScheduledShallowView) => {
          switch (billCalculatorFilterStore.timeGridYAxis) {
            case ETimeGridYAxisType.DATE:
              sp.scheduledPeriods.forEach((s) => {
                const fromDate = moment(new Date(s.fromDate));
                const toDate = moment(new Date(s.toDate));

                const day = fromDate.day();
                const columnId = fromDate.toDate().toISOString();
                const columnData = fromDate.toDate();
                const generated = {
                  title: sp.title,
                  columnData,
                  startTime: fromDate
                    .clone()
                    .diff(fromDate.clone().startOf("day"), "minutes"),
                  endTime: toDate
                    .clone()
                    .diff(toDate.clone().startOf("day"), "minutes"),
                  date: fromDate.toDate(),
                  data: sp,
                };

                if (generated) {
                  // need to collect the event since event is generated
                  if (!groupByDateOrDevId[day]) {
                    // create a column if not exist
                    groupByDateOrDevId[day] = {
                      columnId,
                      columnData,
                      columnIndex: sundayStartToMondayStart(day),
                      events: [],
                    };
                  }
                  // then collect the event
                  groupByDateOrDevId[day].events.push(generated);
                }
              });
              break;
            case ETimeGridYAxisType.DEVICE:
              sp.scheduledPeriods.forEach((s) => {
                sp.powerConsumers.forEach((device) => {
                  const fromDate = moment(new Date(s.fromDate));
                  const toDate = moment(new Date(s.toDate));
                  const columnIndex = deviceHeaders.findIndex(
                    (deviceHeader) => device.id === deviceHeader.deviceId
                  );
                  if (columnIndex > -1) {
                    const matchedDeviceHeader = deviceHeaders[columnIndex];
                    const columnId = matchedDeviceHeader.deviceId;
                    const columnData = matchedDeviceHeader.deviceId;
                    const generated = {
                      title: sp.title,
                      columnData,
                      startTime: fromDate
                        .clone()
                        .diff(fromDate.clone().startOf("day"), "minutes"),
                      endTime: toDate
                        .clone()
                        .diff(toDate.clone().startOf("day"), "minutes"),
                      date: fromDate.toDate(),
                      data: sp,
                    };

                    if (
                      generated &&
                      moment(s.fromDate)
                        .startOf("day")
                        .isSame(
                          moment(
                            billCalculatorFilterStore.selectedDate
                          ).startOf("day")
                        )
                    ) {
                      // need to collect the event since event is generated
                      if (!groupByDateOrDevId[matchedDeviceHeader.deviceId]) {
                        // create a column if not exist
                        groupByDateOrDevId[matchedDeviceHeader.deviceId] = {
                          columnId,
                          columnData,
                          columnIndex,
                          events: [],
                        };
                      }
                      // then collect the event
                      groupByDateOrDevId[
                        matchedDeviceHeader.deviceId
                      ].events.push(generated);
                    }
                  }
                });
              });
              break;
          }
        }
      );
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [
    manualScheduleResponse,
    validForRequest,
    billCalculatorFilterStore.selectedDate,
    startDate,
    isScheduleDeleted,
    billCalculatorFilterStore.timeGridYAxis,
    deviceHeaders,
  ]);

  const onFilter = (
    selectedSpace: ISpaceView,
    date: Date,
    timeGridYAxis?: ETimeGridYAxisType
  ) => {
    const filterSelectedSpace = billCalculatorFilterStore.selectedSpace;

    const isSelectedSpaceEqual =
      filterSelectedSpace.clusterId === selectedSpace.clusterId &&
      filterSelectedSpace.id === selectedSpace.id;

    dispatch(
      setBillCalculatorFilter({
        ...billCalculatorFilterStore,
        selectedSpace,
        selectedDate: moment(date).valueOf(),
        selectedSpaceDetails: !isSelectedSpaceEqual
          ? {
              spaceClusterId: selectedSpace.clusterId,
              spaceId: selectedSpace.id,
            }
          : billCalculatorFilterStore.selectedSpaceDetails,
        timeGridYAxis: timeGridYAxis || billCalculatorFilterStore.timeGridYAxis,
      })
    );
  };

  useEffect(() => {
    if (selectedDate) {
      onFilter(billCalculatorFilterStore.selectedSpace, selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (
      billingSpaceFilterStore.spaceCluster.id &&
      billingSpaceFilterStore.spaceCluster.rootSpace.id &&
      selectedTariff.value
    ) {
      triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster({
        spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
        subRootSpaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
        year: moment(new Date()).year(),
        month: moment(new Date()).month() + 1,
        zoneOffset: offsetToString(moment().utcOffset()),
        tariffId: selectedTariff.value,
        scheduleUsageType: isCheckedManual
          ? EScheduleTagType.ALL
          : EScheduleTagType.ESTIMATOR,
      })
        .unwrap()
        .then((res) => {
          setScheduleMonthlyEnergyForSubSpaceCluster(res);
          setIsLoading(true);
        })
        .catch(() => {
          setScheduleMonthlyEnergyForSubSpaceCluster({
            ...defaultScheduleMonthlyEnergyForSubSpaceCluster,
          });
        });
    }
  }, [
    billingSpaceFilterStore.spaceCluster.id,
    billingSpaceFilterStore.spaceCluster.rootSpace.id,
    billCalculatorFilterStore.selectedDate,
    isScheduleDeleted,
    selectedTariff.value,
    isCheckedManual,
  ]);

  useEffect(() => {
    if (tariffs.length > 0) {
      setSelectedTariff(
        tariffs.find(
          (tariff) =>
            tariff?.data?.code ===
            billingSpaceFilterStore.spaceCluster.serviceProviderAccount
              .tariffCode
        ) ?? { label: "", value: "" }
      );
    }
  }, [
    billingSpaceFilterStore.spaceCluster.serviceProviderAccount.tariffCode,
    tariffs,
  ]);

  useEffect(() => {
    setWeekRange(
      getWeekRange(
        new Date(billCalculatorFilterStore.selectedDate),
        billCalculatorFilterStore.selectedDate
      )
    );
  }, [billCalculatorFilterStore.selectedDate]);

  const sendSelectedSpace = (space: ISpaceView) => {
    dispatch(
      setBillCalculatorFiltersSpaceAndSpaceDetails({
        selectedSpace: space,
        selectedSpaceDetails: {
          spaceClusterId: space.clusterId,
          spaceId: space.id,
        },
      })
    );
  };

  const onClickThisWeekButton = () => {
    const currentDate = new Date();
    const selectedDate = currentDate.getTime();
    const firstDayOfWeek = getWeekRange(currentDate, selectedDate)[0];
    setSelectedDate(firstDayOfWeek);
  };

  const onSaveToSchedule = () => {
    triggerSaveToSchedule({
      spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
      subRootSpaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
      year: +moment(billCalculatorFilterStore.selectedDate).year(),
      month: +moment(billCalculatorFilterStore.selectedDate).month() + 1,
      zoneOffset: offsetToString(moment().utcOffset()),
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Saved to schedule successfully");
      })
      .catch(() => {
        showErrorMessage("Saving to schedule failed");
      });
  };

  const weeklyScheduleEstimation = (
    <StatsViewTypeTwo
      variant="bg-icon-2"
      labelValue={
        Helper.roundTo2(
          weeklyManualScheduleEstimation?.manualScheduleEnergy?.unit || 0
        ) + ""
      }
      title="ESTIMATED UNITS"
      isFetching={isFetchingViewWeeklyManualSchedules}
    />
  );

  const weeklySchedulePercentage = (
    <StatsViewTypeTwo
      variant="bg-icon-2"
      labelValue={
        Helper.roundTo2(
          weeklyManualScheduleEstimation?.manualSchedulePercentage || 0
        ) + "%"
      }
      title="OF TOTAL UNITS"
      isFetching={isFetchingViewWeeklyManualSchedules}
      infoText="Percentage compared to the Space Cluster"
    />
  );

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 ? (
        <>
          <div className="container-white">
            <BillCalculatorTabView
              isFetching={isFetchingViewManualScheduleMonthlyEnergy && loading}
              scheduledSpaceClusterAverageWeeklyEnergy={
                scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterAverageWeeklyEnergy
              }
              spaceClusterEstimatedConsumption={
                scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterEnergy
              }
              tariffs={tariffs}
              selectedTariff={selectedTariff}
              setSelectedTariff={setSelectedTariff}
              onSaveToSchedule={onSaveToSchedule}
              isCheckedManual={isCheckedManual}
              setIsCheckedManual={setIsCheckedManual}
            />
          </div>
          <div
            className={`grid-overflow-second container-white position-relative mt-3`}
          >
            <Row>
              <Col>
                <SpacePathViewBreadcrumb
                  selectedSpace={{
                    clusterId:
                      billCalculatorFilterStore.selectedSpace.clusterId,
                    spaceId: billCalculatorFilterStore.selectedSpace.id,
                  }}
                  sendSelectedSpace={sendSelectedSpace}
                />
              </Col>
            </Row>
            <Row
              className={`align-items-start ${
                billCalculatorFilterStore.timeGridYAxis ===
                ETimeGridYAxisType.DEVICE
                  ? `mt-3`
                  : `my-3`
              }`}
            >
              <Col className={`col-12 col-xxl-9`}>
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <AppDatePicker
                      selectedDate={
                        new Date(billCalculatorFilterStore.selectedDate)
                      }
                      onChange={(date: Date) => {
                        onFilter(billCalculatorFilterStore.selectedSpace, date);
                      }}
                      isInput={true}
                      showDate={false}
                    />
                  </Col>
                  <Col className="col-auto">
                    <NavigateWeeks
                      selectedDate={
                        new Date(billCalculatorFilterStore.selectedDate)
                      }
                      handleDateChange={(date: Date) => {
                        onFilter(billCalculatorFilterStore.selectedSpace, date);
                        setIsLoading(false);
                      }}
                    />
                  </Col>
                  <Col className="text-light font-weight-500 font-size-12 col-auto">
                    {getMonthRangeToDisplay(weekRange)}
                  </Col>
                  <Col className="col-6 col-sm-auto mt-3 mt-sm-0 ps-sm-0">
                    <AppButton
                      text="This Week"
                      size="medium"
                      onClick={onClickThisWeekButton}
                      className="font-size-12 font-weight-500"
                      variant="transparentWithBorder"
                    />
                  </Col>
                  <>
                    <Col className={`${styles.nowBtn} col-6 col-sm-auto`}>
                      <AppButton
                        text="Now"
                        size="medium"
                        onClick={() => {
                          setIsGoToCurrentTime(true);
                          onClickThisWeekButton();
                        }}
                        className="font-size-12 font-weight-500"
                        variant="transparentWithBorder"
                      />
                    </Col>
                    <Col
                      className="col-6 col-sm-auto"
                      onClick={() => {
                        onFilter(
                          billCalculatorFilterStore.selectedSpace,
                          new Date(billCalculatorFilterStore.selectedDate),
                          billCalculatorFilterStore.timeGridYAxis ===
                            ETimeGridYAxisType.DEVICE
                            ? ETimeGridYAxisType.DATE
                            : ETimeGridYAxisType.DEVICE
                        );
                      }}
                    >
                      <Row
                        className={`${styles.miniCalendarToggler} cursor-pointer align-items-center justify-content-center py-2 mx-0`}
                      >
                        <Col className="col-auto">
                          <MaterialIcon icon="swap_horiz" />
                        </Col>
                        <Col className="col-auto text-light font-size-12 font-weight-500">
                          {billCalculatorFilterStore.timeGridYAxis ===
                          ETimeGridYAxisType.DEVICE
                            ? ETimeGridYAxisType.DATE
                            : ETimeGridYAxisType.DEVICE}
                        </Col>
                      </Row>
                    </Col>
                  </>
                </Row>
              </Col>
              <Col
                className={`${
                  billCalculatorFilterStore.timeGridYAxis ===
                    ETimeGridYAxisType.DEVICE && `d-none d-xxl-block`
                } col-12 col-xxl-3 mt-3 mt-xxl-0`}
              >
                <Row className="align-items-center justify-content-start justify-content-xxl-end">
                  <Col className="col-12 col-sm col-xxl-6 mt-2 mt-sm-0">
                    {weeklyScheduleEstimation}
                  </Col>
                  <Col className="col-12 col-sm col-xxl-6 mt-2 mt-sm-0">
                    {weeklySchedulePercentage}
                  </Col>
                </Row>
              </Col>
            </Row>
            {billCalculatorFilterStore.timeGridYAxis ===
              ETimeGridYAxisType.DEVICE && (
              <>
                <Row className="ps-2 mt-2 mt-xxl-0">
                  <Col>
                    <MiniCalendar
                      startDate={weekRange[0]}
                      selectedDate={
                        new Date(billCalculatorFilterStore.selectedDate)
                      }
                      handleDateChange={(date: Date) => {
                        onFilter(billCalculatorFilterStore.selectedSpace, date);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col className="col-6 d-block d-xxl-none">
                    {weeklyScheduleEstimation}
                  </Col>
                  <Col className="col-6 d-block d-xxl-none">
                    {weeklySchedulePercentage}
                  </Col>
                </Row>
              </>
            )}
            <div>
              <Row className="mt-2">
                <Col>
                  <>
                    {!noDevices ? (
                      <div
                        className={`${
                          !noSmartDevices && styles.calendarContent
                        }`}
                      >
                        {calendarContent}
                      </div>
                    ) : (
                      <div className="container-dash mt-4">
                        <Row>
                          <Col className="text-center text-light font-size-12">
                            This space does not contain any devices.
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                </Col>
              </Row>
            </div>
            <SpinnerModal
              show={
                isFetchingViewManualScheduleMonthlyEnergy ||
                isFetchingViewWeeklyManualSchedules ||
                isFetchingSpace ||
                isTariffFetching ||
                isLoadingSaveToSchedule
              }
            />
          </div>
          <BillCalculatorDeleteModal
            show={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onClose={() => setShowDeleteModal(false)}
            spaceClusterId={
              billCalculatorFilterStore.selectedSpaceDetails.spaceClusterId
            }
            subRootSpaceId={
              billCalculatorFilterStore.selectedSpaceDetails.spaceId
            }
            deleteScheduleData={deleteScheduleData}
            onSuccess={() => {
              setIsScheduleDeleted((ps) => !ps);
            }}
          />
        </>
      ) : (
        <>
          {!isFetchingSpaceCluster && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <SpinnerModal show={isFetchingSpaceCluster} />
    </div>
  );
};

export default BillCalculator;
