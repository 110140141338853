import { Col, Row } from "react-bootstrap";

interface IItems {
  itemName: string;
  id: number;
}

interface IProps {
  items: IItems[];
  onChange: (id: number) => void;
  selectedItemId: number;
}

const SettingItem = (props: IProps) => {
  const { items, onChange, selectedItemId } = props;

  return (
    <>
      {items.map((i, index) => {
        return (
          <Row key={i.id} className={`${index !== 0 && `mt-2`}`}>
            <Col
              className={`font-weight-400 font-size-12 text-dark ${
                selectedItemId === i.id && `bg-gray-8`
              } py-2 px-3 rounded cursor-pointer`}
              onClick={() => {
                onChange(i.id);
              }}
            >
              {i.itemName}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default SettingItem;
