import React from "react";
import { Col, Row } from "react-bootstrap";
import { ETimePeriod } from "../../oversight-core/enums/time-period";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";

interface IProps {
  timePeriods: {
    label: string;
    value: ETimePeriod;
  }[];
  selectedTimePeriod: number;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<number>>;
}

const TimePeriodSwitcher = (props: IProps) => {
  const { timePeriods, selectedTimePeriod, setSelectedTimePeriod } = props;

  return (
    <div className="border border-gray-9 rounded-3 py-2 px-4">
      <Row className="align-items-center">
        <Col className="col-auto">
          <MaterialIcon
            icon="chevron_left"
            color="#69768B"
            size={12}
            className={`${
              selectedTimePeriod > 0 ? "cursor-pointer" : "cursor-disabled"
            }`}
            onClick={() => {
              if (selectedTimePeriod > 0) {
                setSelectedTimePeriod((ps) => ps - 1);
              }
            }}
          />
        </Col>
        <Col className="font-weight-500 font-size-12 text-dark text-center">
          {timePeriods[selectedTimePeriod].label}
        </Col>
        <Col className="col-auto">
          <MaterialIcon
            icon="chevron_right"
            color="#69768B"
            size={12}
            className={`${
              selectedTimePeriod < timePeriods.length - 1
                ? "cursor-pointer"
                : "cursor-disabled"
            }`}
            onClick={() => {
              if (selectedTimePeriod < timePeriods.length - 1) {
                setSelectedTimePeriod((ps) => ps + 1);
              }
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TimePeriodSwitcher;
