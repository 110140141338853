import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EEnergySourceType } from "../../../../../shared/oversight-core/enums/energy-source-type";
import { EUnitType } from "../../../../../shared/oversight-core/enums/unit-type";
import BatteryInfoCard from "../../../../../shared/oversight-core/shared-components/battery-info-card/battery-info-card";
import SolarInfoCard from "../../../../../shared/oversight-core/shared-components/solar-info-card/solar-info-card";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AreaChart from "../../../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ArrowButton from "../../../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { findEnergySourceType } from "../../../../../shared/oversight-core/utils/find-energy-source-type";

interface ChartData {
  solarData: number[];
  labels: string[];
}

const defaultChartDataValues = {
  solarData: [],
  labels: [],
};

const unitTypes: Option[] = [
  {
    value: EUnitType.POWER,
    label: "Power(kW)",
  },
  {
    value: EUnitType.ENERGY,
    label: "Energy(kWh)",
  },
];

const Battery = () => {
  const navigate = useNavigate();

  const [selectedUnitType, setSelectedUnitType] = useState<Option>({
    ...unitTypes[0],
  });
  const [chartData, setChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });

  return (
    <>
      <Row className="mx-0">
        <Col>
          <ArrowButton onBack={() => navigate(-1)} />
        </Col>
      </Row>
      <Row className="mt-1 g-4">
        <Col className="col-12 col-lg-4">
          <BatteryInfoCard value={65} batteryCapacity={50} />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-4 col-xxl">
          <SolarInfoCard
            title="Charging From"
            value={findEnergySourceType(EEnergySourceType.SOLAR_PV)}
            icon="bolt"
            iconColor="#2F3E71"
            backgroundColor="#EEEEF8"
            isSwapTitle
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-4 col-xxl">
          <SolarInfoCard
            title="Usage Available"
            value="2h 15m"
            icon="av_timer"
            iconColor="#A4635D"
            backgroundColor="#F8F3EE"
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
          <SolarInfoCard
            title="Average Daily Use"
            value="45%"
            icon="electric_meter"
            iconColor="#345B7A"
            backgroundColor="#EFF6FC"
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
          <SolarInfoCard
            title="Saving Due to Battery "
            value="Rs. 1541"
            icon="energy_savings_leaf"
            iconColor="#32A851"
            backgroundColor="#F3F8EE"
          />
        </Col>
      </Row>
      <div className="container-white position-relative mt-4">
        <Row className="justify-content-end">
          <Col className="col-auto">
            <AppSelect
              selectedValue={{ ...selectedUnitType }}
              options={[...unitTypes]}
              onChangeOption={(selectedOption) => {
                setSelectedUnitType(selectedOption);
              }}
              id="unit-type"
            />
          </Col>
        </Row>
        <AreaChart
          borderColor1="#5030B0"
          backgroundColor1="#4C29CC17"
          label1="Battery Level"
          yAxesUnit="%"
          labels={chartData.labels}
          data1={chartData.solarData}
          isChartDataAvailable={false}
        />
        <SpinnerModal show={false} />
      </div>
    </>
  );
};

export default Battery;
